import * as React from 'react';
import PropTypes from 'prop-types';

import MontereyWheel from '../components/monterey-wheel';

import OdooScreensMockup from '../images/odoo-screens-mockup';
import OdooHomepageDesktop from '../images/odoo-homepage-desktop';

const MontereySoftware = ({ bgcolor }) => (
  <div className={`pt-4 pb-16 overflow-hidden ${bgcolor}`}>
    <div className="w-32 py-4 mx-auto text-teal-700">
      <MontereyWheel />
    </div>
    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="mx-auto text-base max-w-prose lg:max-w-none">
        <p className="text-base font-semibold leading-6 tracking-wide text-teal-600 uppercase">
          Work with us!
        </p>
        <h1 className="mt-2 mb-8 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          What makes Monterey Software{' '}
          <span className="text-teal-600">different</span>?
        </h1>
      </div>
      <div className="relative z-10 mx-auto mb-8 text-base max-w-prose lg:max-w-5xl lg:mx-0 lg:pr-72">
        <p className="text-xl leading-7 text-gray-500">
          We&apos;ll chart you a course with the{' '}
          <span className="font-bold text-teal-600">highest payback</span> and{' '}
          <span className="font-bold text-teal-600">lowest risk</span>. We want
          you to succeed with Odoo, and we&apos;re committed to getting you
          there!
        </p>
      </div>
      <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
        <div className="relative z-10 mb-12 lg:mb-0">
          <div className="mx-auto mb-10 prose text-gray-500 lg:max-w-none">
            <p>
              We start with what counts most &mdash; essential modules for your
              process. Limiting scope gets you up and running quickly.
              you&apos;ll benefit as you learn the system. Once you&apos;re
              comfortable with Odoo, adding new functions is easy &mdash;
              we&apos;ll work with you to implement them at your preferred pace.
            </p>
            <p>
              Consider these things as you evaluate partners for your Odoo
              project:
            </p>
            <h2>
              Odoo is <span className="text-teal-600">all we do</span>
            </h2>
            <p>
              Rather than offer an assortment of software platforms, we
              specialize in just one&mdash;Odoo&mdash;and we serve only clients
              for whom Odoo is a good fit.
            </p>
            <p>
              This allows us to deepen our Odoo expertise with every project,
              expertise we then provide to all our clients, past, present, and
              future.
            </p>
            <h2>
              We know how to fit Odoo to{' '}
              <span className="text-teal-600">your situation</span>
            </h2>
            <p>
              Because we know Odoo so well, we can introduce Odoo to your
              operation with little customization and little disruption&mdash;
              lowing your risk and keeping implementation time to a minimum.
            </p>
            <h2>
              We&apos;ll get you up and running{' '}
              <span className="text-teal-600">quickly</span>
            </h2>
            <p>
              The sooner you experience Odoo as the basis of your business
              process, the more clearly you&apos;ll see how Odoo can be
              profitably extended to all parts of your operation.
            </p>
            <p>
              We&apos;ll plan the project in stages, beginning with a quickly
              installed, low-risk application. you&apos;ll have immediate
              real-life experience using Odoo, making it easy for us to plan
              future stages together.
            </p>
            <h2>
              Our documentation and training is{' '}
              <span className="text-teal-600">world class</span>
            </h2>
            <p>
              We provide prompt and courteous support after the installation is
              complete. But our hope&mdash;and yours!&mdash;is that you
              won&apos;t need much. To insure this, we invest heavily in
              training that is quick, pleasant, and effective, and documentation
              that is clear, concise, and usable.
            </p>
            <p>
              Once you&apos;re up and running you&apos;ll be prepared to deal
              with most situations, and be able to look up answers to any
              remaining questions.
            </p>
            {/* <h2>
              We&apos;ll quote you a{' '}
              <span className="text-teal-600">fixed price and schedule</span>
            </h2>
            <p>
              Sometime a project will be complex or uncertain enough to call for
              an hourly rate, but we much prefer to do a detailed analysis of
              your business and then tell you exactly what the job will cost and
              how long it will take.
            </p>
            <p>
              you&apos;ll be able to decide up front whether a project is worth
              your time and money, budget the project accurately, and relax
              knowing that we&apos;ll absorb any uncertainties on our end.
            </p> */}
          </div>
          <div className="flex mx-auto text-base max-w-prose lg:max-w-none">
            <div className="rounded-md shadow">
              <a
                href="/get-a-demo/"
                className="flex items-center justify-center w-full px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-teal-600 border border-transparent rounded-md hover:bg-teal-500 focus:outline-none focus:border-teal-700 focus:ring-teal"
              >
                Get a demo
              </a>
            </div>
            <div className="ml-4 rounded-md shadow">
              <a
                href="/about-odoo/"
                className="flex items-center justify-center w-full px-5 py-3 text-base font-medium leading-6 text-teal-600 transition duration-150 ease-in-out bg-white border border-transparent rounded-md hover:text-teal-500 focus:outline-none focus:border-teal-300 focus:ring-teal"
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
        <div className="relative mx-auto text-base max-w-prose lg:max-w-none">
          <svg
            className="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)"
            />
          </svg>
          <div
            className="relative mt-10 -mx-4 lg:mt-0"
            style={{ width: '592px' }}
          >
            <OdooScreensMockup />
            <OdooHomepageDesktop className="mt-48" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

MontereySoftware.propTypes = {
  bgcolor: PropTypes.string,
};

MontereySoftware.defaultProps = {
  bgcolor: 'bg-white',
};

export default MontereySoftware;
