import * as React from 'react';

import Layout from '../components/layout';

import DropTheBall from './drop-the-ball';
import Hero from './hero';
import WhatIsERP from './what-is-erp';
import OdooSolution from './odoo-solution';
import MontereySoftware from './monterey-software';

export default () => (
  <Layout
    seoTitle="Monterey Software | Odoo ERP Specialists"
    seoImage="https://www.softwareadvice.com/resources/wp-content/uploads/austin-mathis-empire-candle-2.jpg"
  >
    <Hero bgcolor="bg-white" />
    <DropTheBall bgcolor="bg-gray-50" />
    <WhatIsERP bgcolor="bg-white" />
    <OdooSolution bgcolor="bg-gray-50" />
    <MontereySoftware bgcolor="bg-white" />
  </Layout>
);
