import * as React from 'react';
import PropTypes from 'prop-types';

const DropTheBall = ({ bgcolor }) => (
  <div className={`py-12 ${bgcolor}`}>
    <div className="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
      <div className="lg:text-center">
        <p className="text-base font-semibold leading-6 tracking-wide text-teal-600 uppercase">
          Serve customers better
        </p>
        <h3 className="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          No more dropped balls
        </h3>
        <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-500 lg:mx-auto">
          Odoo shows you where your entire business stands at every
          instant&mdash;what&apos;s been started, what&apos;s finished,
          what&apos;s on schedule, what&apos;s late, what you&apos;re waiting
          on, who&apos;s responsible.
        </p>
      </div>
      <div className="mt-10">
        <ul className="md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
          <li>
            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center w-12 h-12 text-white bg-teal-500 rounded-md">
                  {/* Heroicon name: globe-alt */}
                  <svg
                    className="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                    />
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <h4 className="text-lg font-medium leading-6 text-gray-900">
                  No more sticky notes
                </h4>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  No matter how the info comes in, Odoo provides a way to enter
                  it easily and quickly. And once it&apos;s entered, you&apos;ll
                  never lose track of it.
                </p>
              </div>
            </div>
          </li>
          <li className="mt-10 md:mt-0">
            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center w-12 h-12 text-white bg-teal-500 rounded-md">
                  {/* Heroicon name: scale */}
                  <svg
                    className="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                    />
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <h4 className="text-lg font-medium leading-6 text-gray-900">
                  No more outdated spreadsheets
                </h4>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  Need to do some data analysis? No need to create yet another
                  Excel sheet, <strong>Odoo Spreadsheet</strong> has you
                  covered. And you&apos;ll never need to refresh your data, Odoo
                  is always up to date.
                </p>
              </div>
            </div>
          </li>
          <li className="mt-10 md:mt-0">
            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center w-12 h-12 text-white bg-teal-500 rounded-md">
                  {/* Heroicon name: lightning-bolt */}
                  <svg
                    className="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    />
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <h4 className="text-lg font-medium leading-6 text-gray-900">
                  No more finger pointing
                </h4>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  Each person has a dashboard displaying their assigned tasks.
                  Tasks are only cleared (and moved on to the next person) when
                  their portion is done. And the history of a task is always
                  available.
                </p>
              </div>
            </div>
          </li>
          <li className="mt-10 md:mt-0">
            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center w-12 h-12 text-white bg-teal-500 rounded-md">
                  {/* Heroicon name: annotation */}
                  <svg
                    className="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                    />
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <h4 className="text-lg font-medium leading-6 text-gray-900">
                  No more fumbled handoffs
                </h4>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  Responsibility remains with a person until their part of a
                  task is done. And once it&apos;s done, the task is
                  automatically assigned to the next person in line&mdash;and
                  that person is immediately notified.
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

DropTheBall.propTypes = {
  bgcolor: PropTypes.string,
};

DropTheBall.defaultProps = {
  bgcolor: 'bg-white',
};

export default DropTheBall;
