import * as React from 'react';
import PropTypes from 'prop-types';

import OdooAppArc from '../images/odoo-app-arc';
import OldWayNewWay from '../images/old-way-new-way';
import OdooCRMScreenshot from '../images/odoo-crm-screenshot';
import OdooPOScreenshot from '../images/odoo-po-screenshot';

const OdooSolution = ({ bgcolor }) => (
  <div className={`py-3 overflow-hidden ${bgcolor} lg:py-4`}>
    <div className="relative max-w-xl px-4 mx-auto sm:px-6 lg:px-8 lg:max-w-screen-xl">
      <svg
        className="absolute hidden transform -translate-x-1/2 lg:block left-full -translate-y-1/4"
        width={404}
        height={784}
        fill="none"
        viewBox="0 0 404 784"
      >
        <defs>
          <pattern
            id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
            x={0}
            y={0}
            width={20}
            height={20}
            patternUnits="userSpaceOnUse"
          >
            <rect
              x={0}
              y={0}
              width={4}
              height={4}
              className="text-gray-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width={404}
          height={784}
          fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
        />
      </svg>

      <div className="relative">
        <div className="mx-auto">
          <img
            alt=""
            className="mx-auto py-9"
            src="/img/logos/odoo.svg"
            width="700"
          />
        </div>

        <h3 className="text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl sm:leading-10">
          A powerful and <span className="text-teal-600">affordable</span> ERP
          solution
        </h3>
        <p className="max-w-3xl mx-auto mt-8 text-xl leading-7 text-center text-gray-500">
          A growing small business often starts with one package, then gradually
          adds others as pain points arise,{' '}
          <em>if and when it can afford to</em> &mdash; hoping the packages will
          work well together, but soon learning they don&apos;t.
        </p>
        <p className="max-w-3xl mx-auto mt-8 mb-8 text-xl leading-7 text-center text-gray-500">
          The Odoo integrated ERP system lets you add functions{' '}
          <em>gradually</em> and <em>affordably</em>. Start with just one
          module, and as you add new functions you find that the pieces fit
          together seamlessly.
        </p>
      </div>
      <div>
        <OldWayNewWay className="mx-auto" />
      </div>
      <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div className="relative">
          <h4 className="text-2xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
            Buy <span className="text-teal-600">only</span> the functions you
            need
          </h4>
          <p className="mt-3 text-lg leading-7 text-gray-500">
            Odoo licenses its apps (functions) individually. You can begin with
            just one&mdash;your choice!&mdash;add others as you are ready,
            easily drop those you decide later aren&apos;t useful.
          </p>
          <ul className="mt-10">
            <li>
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center w-12 h-12 text-white bg-teal-500 rounded-md">
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg font-medium leading-6 text-gray-900">
                    Start simple, expand when ready
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    You can be up and running with just one module, like CRM or
                    Sales. Later you can expand your ERP incrementally, module
                    by module, expanding your capabilities while minimising
                    risk.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center w-12 h-12 text-white bg-teal-500 rounded-md">
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg font-medium leading-6 text-gray-900">
                    No integration worries
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Wnen you add a new package (blogging, CRM, scheduling), you
                    must somehow get it to work together with your current
                    software packages. Not so with Odoo! All apps are built on a
                    single unified database, so any function you add is
                    guaranteed to work with those you currently use.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center w-12 h-12 text-white bg-teal-500 rounded-md">
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 10V3L4 14h7v7l9-11h-7z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg font-medium leading-6 text-gray-900">
                    Apps are priced per installation, not per user
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    When considering adding an app, no need to worry about how
                    many of your users need access to it &mdash; one price
                    includes access for all your Odoo users, current and future.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="relative mt-10 -mx-4 lg:mt-0">
          <svg
            className="absolute transform -translate-x-1/2 translate-y-16 left-1/2 lg:hidden"
            width={784}
            height={404}
            fill="none"
            viewBox="0 0 784 404"
          >
            <defs>
              <pattern
                id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={784}
              height={404}
              fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
            />
          </svg>
          <OdooAppArc className="relative mx-auto" />
        </div>
      </div>
      <svg
        className="absolute hidden transform translate-x-1/2 translate-y-12 lg:block right-full"
        width={404}
        height={784}
        fill="none"
        viewBox="0 0 404 784"
      >
        <defs>
          <pattern
            id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
            x={0}
            y={0}
            width={20}
            height={20}
            patternUnits="userSpaceOnUse"
          >
            <rect
              x={0}
              y={0}
              width={4}
              height={4}
              className="text-gray-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width={404}
          height={784}
          fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
        />
      </svg>
      <div className="relative mt-12 sm:mt-16 lg:mt-24">
        <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="lg:col-start-2">
            <h4 className="text-2xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
              Same interface for all apps
            </h4>
            <p className="mt-3 text-lg leading-7 text-gray-500">
              The Odoo interface is fast, modern, and intuitive. Once a person
              learns their own workspace, they will be comfortable with other
              apps they may need to use, now or in the future.
            </p>
            <ul className="mt-10">
              <li>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 text-white bg-teal-500 rounded-md">
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h5 className="text-lg font-medium leading-6 text-gray-900">
                      Use your favorite web browser to manage all parts of Odoo
                    </h5>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Anyone with experience using a web browser is most of the
                      way to being an Odoo interface expert!
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 text-white bg-teal-500 rounded-md">
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h5 className="text-lg font-medium leading-6 text-gray-900">
                      A real-time window into the state of your business
                    </h5>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Since all Odoo apps are built upon a single integrated
                      database, any changes to the data (e.g. new sales orders,
                      receiving inventory) are instantly available to all Odoo
                      users.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="relative mt-10 -mx-4 lg:mt-0 lg:col-start-1">
            <svg
              className="absolute transform -translate-x-1/2 translate-y-16 left-1/2 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={784}
                height={404}
                fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
              />
            </svg>
            <div style={{ width: '490px' }} className="relative mx-auto my-10">
              <OdooCRMScreenshot />
              <OdooPOScreenshot />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

OdooSolution.propTypes = {
  bgcolor: PropTypes.string,
};

OdooSolution.defaultProps = {
  bgcolor: 'bg-white',
};

export default OdooSolution;
